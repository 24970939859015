const userModule = {
    state: () => ({
        user: {
            id: null,
            username: null,
            role: null
        },
        userLoggedIn: false
    }),
    mutations: {
        setUser(state, payload) {
            state.user.id = payload.id;
            state.user.username = payload.userName;
            state.user.role = payload.userRole;
            state.userLoggedIn = payload.userLoggedIn;
        }
    },
    getters: {
        getUsername(state) {
            return state.user.username;
        },
        isLoggedIn(state) {
            return state.userLoggedIn;
        }
    },
    actions: { 
        setUser({ commit }, payload) {
          //console.log(payload)
            localStorage.setItem('token', payload.result);
            commit('setUser', payload);
            window.location.href = '/';
        },
        logout({ commit }) {
            localStorage.removeItem('token');
            commit('setUser', {
                id: null,
                username: null,
                role: null,
                userLoggedIn: false
            });
            window.location.href = '/auth/login';
        },
        getUserInfo({ commit }) {
            axios.get('auth/user-info').then((response) => {
                if(response.data.id != null) {
                    response.data.userLoggedIn = true;
                    commit('setUser', response.data);       
                }
            }).catch((error) => {
                //console.log(error);
            });
        }
    }
}
export default userModule
