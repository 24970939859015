import { createRouter, createWebHistory } from 'vue-router';


import store from '../store';
import unused from './unused.js'
        
// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    //dashboard
    // { path: '/', name: 'Home', component: () => import(/* webpackChunkName: "Home" */ '../views/index.vue') },

    { path: '/', redirect: '/leagues/nba' },
    
    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/logout',
        name: 'logout',
        redirect: '/auth/login',
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' },
    },
    
    
    // Leagues - NBA
    {
        path: '/leagues/nba',
        name: 'league-nba',
        component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/nba/index.vue'),
    },
    {
        path: '/leagues/nba/game/:id',
        name: 'league-nba-game',
        component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/nba/game.vue'),
    },
    {
        path: '/leagues/nba/parlay',
        name: 'league-nba-parlay',
        component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/nba/parlay.vue'),
    },
  
    // Leagues - Golf
    {
      path: '/leagues/golf',
      name: 'league-golf',
      component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/golf/index.vue'),
    },
    {
      path: '/leagues/golf/player/:id',
      name: 'league-golf-player',
      component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/golf/player.vue'),
    },
    {
      path: '/leagues/golf',
      name: 'league-golf',
      component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/golf/index.vue'),
    },
    {
      path: '/leagues/golf/calculator',
      name: 'league-golf-calculator',
      component: () => import(/* webpackChunkName: "leagues" */ '../views/leagues/golf/calculator.vue'),
    },
      
    // 404 
    { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import(/* webpackChunkName: "not-found" */ '../views/unused/pages/error404') },
    
    // Maintenance
    { path: '/maintenance', name: 'maintenance', component: () => import(/* webpackChunkName: "maintenance" */ '../views/unused/pages/maintenence.vue'), meta: { layout: 'auth' } },
  
    //...unused
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
