<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/img/logo-text-white.png" class="navbar-logo " alt="logo" />
                        </router-link>
                    </li>
<!--                    <li class="nav-item theme-text">-->
<!--                        <router-link to="/" class="nav-link"> Bet On This </router-link>-->
<!--                    </li>-->
                </ul>
                <div class="navbar-item flex-row ms-md-auto" v-if='isLoggedIn'>
                    <div class='mt-1 font-weight-bold'>Welcome Back {{ username }}!</div>
                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
<!--                            <li role="presentation">-->
<!--                                <router-link to="/users/profile" class="dropdown-item">-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-user"-->
<!--                                    >-->
<!--                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>-->
<!--                                        <circle cx="12" cy="7" r="4"></circle>-->
<!--                                    </svg>-->
<!--                                    Profile-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li role="presentation">
                                <router-link to="/" class="dropdown-item" @click='logout()'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    Sign Out
                                </router-link>
                            </li>
                        </ul> 
                    </div>
                </div>
                <div class="navbar-item flex-row ms-md-auto" v-if='!isLoggedIn'>
                    <router-link to="#" @click='goToLogin()'>
                        <button type="button" class="btn btn-primary button-border">Login | Sign Up</button>
<!--                        <div class='m-1 font-weight-bold h5 text-secondary'>-->
<!--                            -->
<!--                            Login-->
<!--                        </div>-->
                    </router-link>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
</template>

<script setup>
    import { useStore } from 'vuex';
    const store = useStore();
    import { useRouter } from 'vue-router';
    import { onMounted, computed } from 'vue';
    const router = useRouter();
    import feather from 'feather-icons';
    
    const username = computed(() => {
        return store.getters.getUsername;
    });
    const isLoggedIn = computed(() => {
        return store.getters.isLoggedIn;
    });
    
    const logout = () => {
        store.dispatch('logout');
        window.location.href = '/auth/login';
    }

    const goToLogin = () => {
        window.location.href = '/auth/login';
    }
    
    onMounted(() => {
        store.dispatch("getUserInfo");
        feather.replace();
    });
    
    
</script>
