<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
<!--                <li class="menu">-->
<!--                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#dashboard" aria-controls="dashboard" aria-expanded="false">-->
<!--                        <div class="">-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="24"-->
<!--                                height="24"-->
<!--                                viewBox="0 0 24 24"-->
<!--                                fill="none"-->
<!--                                stroke="currentColor"-->
<!--                                stroke-width="2"-->
<!--                                stroke-linecap="round"-->
<!--                                stroke-linejoin="round"-->
<!--                                class="feather feather-home"-->
<!--                            >-->
<!--                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>-->
<!--                                <polyline points="9 22 9 12 15 12 15 22"></polyline>-->
<!--                            </svg>-->
<!--                            <span>{{ $t('Home') }}</span>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="24"-->
<!--                                height="24"-->
<!--                                viewBox="0 0 24 24"-->
<!--                                fill="none"-->
<!--                                stroke="currentColor"-->
<!--                                stroke-width="2"-->
<!--                                stroke-linecap="round"-->
<!--                                stroke-linejoin="round"-->
<!--                                class="feather feather-chevron-right"-->
<!--                            >-->
<!--                                <polyline points="9 18 15 12 9 6"></polyline>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                    </a>-->

<!--                    <ul id="dashboard" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">-->
<!--                        <li>-->
<!--                            <router-link to="/" @click="toggleMobileMenu">-->
<!--                                {{ $t('Home') }}-->
<!--                            </router-link>-->
<!--                        </li>-->
<!--&lt;!&ndash;                        <li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <router-link to="/index2" @click="toggleMobileMenu">&ndash;&gt;-->
<!--&lt;!&ndash;                                {{ $t('analytics') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->
<!--                    </ul>-->
<!--                </li>-->

                <li class="menu">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#nba" aria-controls="nba" aria-expanded="false">
                        <div class="">
                            <font-awesome-icon icon="fa-solid fa-basketball" color='primary' />

                            <span>{{ $t('NBA') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <ul id="nba" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/leagues/nba/" @click="toggleMobileMenu">Dashboard</router-link>
                        </li>
<!--                        <li>-->
<!--                            <router-link to="/leagues/nba/teams" @click="toggleMobileMenu">Teams</router-link>-->
<!--                        </li>-->
                        <li>
                            <router-link to="/leagues/nba/parlay" @click="toggleMobileMenu">Parlay Generator</router-link>
                        </li>
                    </ul>
                </li>

<!--                <li class="menu">-->
<!--                <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#golf" aria-controls="golf" aria-expanded="false">-->
<!--                  <div class="">-->
<!--                    <font-awesome-icon icon="fa-solid fa-golf-ball-tee" color='primary' />-->
<!--                    <span>{{ $t('Golf') }}</span>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <svg-->
<!--                      xmlns="http://www.w3.org/2000/svg"-->
<!--                      width="24"-->
<!--                      height="24"-->
<!--                      viewBox="0 0 24 24"-->
<!--                      fill="none"-->
<!--                      stroke="currentColor"-->
<!--                      stroke-width="2"-->
<!--                      stroke-linecap="round"-->
<!--                      stroke-linejoin="round"-->
<!--                      class="feather feather-chevron-right"-->
<!--                    >-->
<!--                      <polyline points="9 18 15 12 9 6"></polyline>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                </a>-->
<!--                <ul id="golf" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">-->
<!--                  <li>-->
<!--                    <router-link to="/leagues/golf/" @click="toggleMobileMenu">Dashboard</router-link>-->
<!--                  </li>-->
<!--&lt;!&ndash;                  <li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link to="/leagues/golf/calculator" @click="toggleMobileMenu">Bet Calculator</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                  </li>&ndash;&gt;-->
<!--                </ul>-->
<!--              </li>-->
                
                <li class="menu">
                    <a class="dropdown-toggle text-white bg-primary button-outline button-border " href="https://discord.gg/HfkstGXnC6" target='_blank'>
                        <div>
                            <font-awesome-icon icon="fa-brands fa-discord" color='accent' />
                            <span>Discord</span>
                        </div>
                    </a>
                  <a class="dropdown-toggle text-white bg-primary button-outline button-border justify-content-center" href="https://buy.stripe.com/8wMcPkeR08Rm77ObIJ" target='_blank'>
                    <div>
                      <span>Support Us!</span>
                    </div>
                  </a>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>
<script>
  import { onMounted, ref } from 'vue';
  import { mapActions } from 'vuex';

  export default {
    data() {

      return {
        menu_collapse: 'dashboard',
      };
    },
    mounted() {
      const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
      if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul) {
          let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
          if (ele) {
            ele = ele[0];
            setTimeout(() => {
              ele.click();
            });
          }
        } else {
          selector.click();
        }
      }
    },
    methods: {
      ...mapActions(['toggleSideBar']),
      toggleMobileMenu() {
        if (window.innerWidth < 991) {
          this.toggleSideBar(!this.$store.state.is_show_sidebar);
        }
      }
    }
  };
</script>
