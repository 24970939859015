import { computed, createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);
import axios from 'axios';
import 'babel-polyfill'

//const base = "http://192.99.15.214:5003/api/";
const base = "https://betonthis.ca/api/";
//const base = "http://localhost:5276/api/";
//const base = "http://localhost:3010/";

// const apiBase = computed(() => {
//   const path = router.currentRoute.value.path;
//   if (path.startsWith('/leagues/nba')) {
//     console.log("basketball")
//     return "http://192.99.15.214:5003/api/";
//   } else {
//     return "http://192.99.15.214:5002/api/";
//   }
// });

axios.defaults.baseURL = base;

// axios
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.post['Content-Type'] = 'application/json';

// axios interceptors for auth middleware
// axios.interceptors.response.use((response) => {
//   return response;
// }, (error) => {
//   if (error.response.status === 401) {
//     store.dispatch('logout');
//   }
//   return Promise.reject(error);
// });


// watch(apiBase, (newBase) => {
//   axios.defaults.baseURL = newBase;
// });

window.axios = axios;

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';
import '@/assets/sass/widgets/widgets.scss';
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/custom-counter.scss';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'

// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as icons from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
library.add(icons.faBasketball, icons.faCheck, faDiscord, icons.faGolfBallTee, icons.faChevronCircleRight);

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

import VueHtml2Canvas from 'vue-html2canvas';

// make chain with everything
app.use(store)
    .use(router)
    .use(head)
    .use(PerfectScrollbar)
    .use(VueNouislider)
    .use(Maska)
    .use(ClientTable)
    .use(VueFormWizard)
    .component('vue3-json-excel', vue3JsonExcel)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(i18n)
    .use(VueHtml2Canvas)
    .mount('#app');
    
