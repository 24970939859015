const settingsModule = {
    state: () => ({
        isDecimal: localStorage.getItem('isDecimal') || true
    }),
    mutations: {
        setIsDecimal(state, payload) {
            state.isDecimal = payload
            localStorage.setItem('isDecimal', payload)
        }
    },
    getters: {
        isDecimal: state => state.isDecimal
    }
}
export default settingsModule
